import React, { Dispatch, FC, SetStateAction } from 'react';
import { TicketMap } from '@ticketevolution/seatmaps-client';
import { Grid, Skeleton } from '@mui/material';
import { useLocation } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import styled from 'styled-components';
import { TicketGroupTEType } from '../../containers/book/book';
import { RangePriceSlider } from './rangePriceSlider';
import { QuantityButton } from './quantityButton';
import { LowPriceButton } from './lowPriceButton';
import { FilterByTag } from './filterByTag';
import { FilterParams } from '../../store/enums';
import { BinocularsFilter } from './binocularsFilter';
import { ClearAllButton } from './clearAllButton';
import { EventBookType } from '../../core/models/events';

const useStyles = makeStyles()((theme) => ({
  sortButtonGroup: {
    display: 'flex',
    gap: 8,
    [theme.breakpoints.down(425)]: {
      justifyContent: 'space-between',
    },
  },
  sliderContainer: {
    width: '324px',
    [theme.breakpoints.down(425)]: {
      width: '100%',
    },
  },
  sortContainer: {
    padding: '40px 0 0 0',
    [theme.breakpoints.down(425)]: {
      flexDirection: 'column',
      padding: '20px 8px 0 8px',
    },
  },
  skeletonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    zIndex: 0,
  },
  title: {
    fontSize: '14px',
    margin: '0 0 20px 0',
  },

  buttonsByTag: {
    display: 'flex',
    gap: 4,
    position: 'relative',
  },
  buttonsByPrice: {
    display: 'flex',
    gap: 4,
  },
  skeleton: {
    transform: 'scale(1)',
  },
}));

const TESchemaSectorSelector: FC<TESchemaSectorSelectorPropsType> = ({
  ticketGroups = [],
  tEVenueId,
  configurationId,
  setSelectedSections,
  selectedSections,
  event,
  isLoading,
  selectTicketGroupId,
}) => {
  const location = useLocation();
  const { classes } = useStyles();
  // @ts-ignore
  const customWindow: any = window;

  const handleSectionClick = (sections: string[]) => {
    setSelectedSections(sections);
  };

  return (
    <Container>
      <SchemeWrapper>
        {(isLoading || !tEVenueId || !configurationId || !ticketGroups || ticketGroups.length <= 0) && (
          <Skeleton className={classes.skeleton} animation={'wave'} width={'70%'} height={'90%'} />
        )}
        {!isLoading && tEVenueId && configurationId && ticketGroups && ticketGroups.length > 0 ? (
          <TicketMap
            selectedSections={selectedSections}
            onSelection={handleSectionClick}
            venueId={tEVenueId}
            configurationId={configurationId.toString()}
            // @ts-ignore
            ticketGroups={ticketGroups}
          />
        ) : null}
      </SchemeWrapper>
      {isLoading && <Skeleton className={classes.skeleton} animation={'wave'} width={'100%'} height={'30%'} />}
      {!isLoading && !selectTicketGroupId && (
        <Grid container justifyContent={'space-between'} className={classes.sortContainer}>
          <Grid item className={classes.sliderContainer}>
            <RangePriceSlider priceRange={[event?.minPrice, event?.maxPrice]} currency={event?.currency} />
          </Grid>
          <Grid item className={classes.sortButtonGroup}>
            <Grid item className={classes.buttonsByPrice}>
              <QuantityButton />
              <LowPriceButton />
            </Grid>
            <Grid item className={classes.buttonsByTag}>
              <FilterByTag icon={FilterParams.DisabledPerson} />
              {/*{customWindow.ENVIRONMENT !== 'production' && <BinocularsFilter />}*/}
              {/*<FilterByTag icon={FilterParams.Parking} />*/}
              {location.search && <ClearAllButton />}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default TESchemaSectorSelector;

type TESchemaSectorSelectorPropsType = {
  selectedSections: string[];
  setSelectedSections: Dispatch<SetStateAction<string[]>>;
  ticketGroups: TicketGroupTEType[];
  tEVenueId: string;
  configurationId: number;
  event: EventBookType;
  isLoading: boolean;
  selectTicketGroupId: string;
};

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  background: linear-gradient(180.62deg, rgba(28, 28, 28, 0) -91.79%, rgba(18, 17, 17, 0.41) 157.66%);
  box-shadow: 4px -4px 16px rgb(0 0 0 / 63%), -4px 4px 16px rgba(0, 0, 0, 0.63);
  border-radius: 12px;
  padding: 32px;
  height: 502px;
  position: relative;
  width: 100%;
  color: #fff;

  @media (max-width: 425px) {
    padding: 24px 16px;
    height: 322px;
  }
`;

const SchemeWrapper = styled.div`
  display: flex;
  justify-content: center;
  color: #000;
  height: 100%;
  width: 100%;
`;
